<template>
  <div class="settings__radio">
    <input
      v-model="radioButtonValue"
      type="radio"
      :id="label"
      :value="apiName"
      :name="name"
    />
    <label :for="label">{{ $t(label) }}</label>
  </div>
</template>

<script>
export default {
  name: 'SettingsProfileRadio',

  props: {
    id: Number,
    name: String,
    apiName: String,
    label: String,
    value: String,
  },
  computed: {
    radioButtonValue: {
      get: function () {
        return this.value;
      },
      set: function () {
        this.$emit('change', this.apiName);
      },
    },
  },

  i18n: {
    messages: {
      'en': {
        'Sunday': 'Sunday',
        'Monday': 'Monday',
        '24 hour': '24 hour',
        '12 hour': '12 hour',
        'DD/MM/YY': 'DD/MM/YY',
        'MM/DD/YY': 'MM/DD/YY',
        'DD.MM.YY': 'DD.MM.YY',
        'MM.DD.YY': 'MM.DD.YY',
        'Display week from Monday': 'Display week from Monday',
        'Display week from Sunday': 'Display week from Sunday',
        'Display week from current day': 'Display week from current day',
      },
      'ru': {
        'Sunday': 'Воскресенье',
        'Monday': 'Понедельник',
        '24 hour': '24 часа',
        '12 hour': '12 часа',
        'DD/MM/YY': 'ДД/ММ/ГГ',
        'MM/DD/YY': 'ММ/ДД/ГГ',
        'DD.MM.YY': 'ДД.ММ.ГГ',
        'MM.DD.YY': 'ММ.ДД.ГГ',
        'Display week from Monday': 'Отображать неделю с понедельника',
        'Display week from Sunday': 'Отображать неделю с воскресенья',
        'Display week from current day': 'Отображать неделю с текущего дня',
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.settings__radio {
  display: flex;
  align-items: center;
  margin: 0 20px 15px 0;

  input {
    display: none;
    transition: .1s ease-in-out;

    &:checked + label::before {
      border: 5px solid $blue;

      @include dark-theme {
        border-color: $darkThemeBlue;
      }
    }
  }

  label {
    width: 96px;
    position: relative;
    padding-left: 25px;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #4D525E;
    cursor: pointer;

    &:before {
      content: "";
      display: flex;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      bottom: 1px;
      border: 1px solid $borderColor;
      transition: .1s ease-in-out;
    }

    @include dark-theme {
      color: $darkThemeTextLight;
    }
  }

  &:hover {
    input {
      &:checked + label::before {
        box-shadow: 0 0 0 0 #FFF;
      }
    }

    label {
      &::before {
        box-shadow: inset 0 0 3px 1px #6C77F43B;
      }
    }
  }
}
</style>
